// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


require("@rails/ujs").start()
// require("turbolinks").start()
// import "@rails/ujs";
// import "turbolinks";
import "jquery"

import $ from 'jquery';

global.$ = jQuery;

// duallist
import "bootstrap4-duallistbox/dist/jquery.bootstrap-duallistbox.js"
import "bootstrap4-duallistbox/dist/bootstrap-duallistbox.css"

// add basictable
import "basictable/jquery.basictable.js"
import "basictable/basictable.css"

// bootstrap
import "bootstrap";
import "../stylesheets/application";
import "@fortawesome/fontawesome-free/js/all";
import "select2";


// Client side validation
import '@client-side-validations/client-side-validations'
import '@client-side-validations/simple-form/dist/simple-form.bootstrap4'

global.toastr = require("toastr")
// sidebar
import Sidebar from './ace'

// add tinymce
import { tinyMce } from "./vendor/tinyMce";
import "@wiris/mathtype-tinymce5";
// import "./plugin.min";
import "tinymce/icons/default";
tinyMce();
// document.addEventListener('turbolinks:load', function () {     });

// duallist
$(".dual-list").bootstrapDualListbox({
    selectorMinimalHeight: 250,
    moveOnSelect: false,
    moveOnDoubleClick: true,
    iconsPrefix: 'fa',
    iconMove: 'fa fa-chevron-circle-right',
    iconRemove: 'fa fa-chevron-circle-left',
});

// select2
import 'select2';// globally assign select2 fn to $ element
import 'select2/dist/css/select2.css';// optional if you have css loader

// select
$('select option:gt(0)').each(function(i){
    $(this).attr('title',$(this).text())
 });

$( "#dropdown" ).select2({
    theme: "bootstrap",
    tokenSeparators: [',']
});


$(".taxonomy_ids").select2({
    tags: true,
    tokenSeparators: [',']
});

$("#tag_list" ).select2();
$(function() {
});

// responsive tables
$('.responsive-basic-table').basictable({breakpoint: 800});

// hide all elements conditional (in question)
$("select").change(function () {
    $('.hide-div-conditionally').css('display','none');

    $("select option:selected").each(function () {
        if($(this).val() == "group_question") {
            $('.select-required').css('display','block');
        }
    });
});

$("select option:selected").each(function () {
    if($(this).val() == "group_question") {
        $('.select-required').css('display','block');
    }
});


/// view option text in full
var optionText, array = [];
$('select').each(function(){
    $(this).find('option').each(function(i,e) {
        $(e).attr('title',$(e).text());
        optionText = $(e).text();
        array = optionText.split(' ');
        $.each(array,function(ind,ele) {
            $(e).text(optionText);
            return false;
        });
    });
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

