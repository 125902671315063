// adds tinymce
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/emoticons';

import "tinymce/skins/content/default/content.min.css"
import "tinymce/skins/content/default/content.css"
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/ui/oxide/skin.min.css";

function tinyMce() {
    tinymce.init({
        selector: 'textarea.tinymce',
        // some other settings, like height, language,
        // order of buttons on your toolbar etc
        plugins: ['table', 'lists','tiny_mce_wiris'],
        // external_plugins: {
        //     'tiny_mce_wiris' : 'plugin.min.js',
        // },
        toolbar: 'undo redo | styleselect | bold italic | textcolor |alignleft aligncenter alignright | tiny_mce_wiris_formulaEditor ',
        skin: false
    });
}

export { tinyMce };
